import React from "react";
import { Link } from "gatsby";

// components
import Icons from "../Icons/Index";

interface Props {
  readonly data: any;
}

const RelatedProducts: React.FC<Props> = ({ data }) => {
  const product = data?.edges || [];
  return (
    <>
      {product.length > 1 ? (
        <div className="uk-margin-large">
          <h2 className="uk-h5 uk-h4@m uk-h3@l uk-h2@xl">Related Products</h2>
          <hr className="uk-divider-small" />
          <div className="uk-margin-large" uk-slider="">
            <div className="uk-position-relative">
              <div className="uk-slider-container">
                <ul
                  className="uk-slider-items uk-grid-match uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l"
                  uk-grid=""
                  style={{margin: "5px 0"}}
                >
                  {product.map(({ node }: any, index: number) => {
                    const application = node.filterType.toLowerCase();

                    return (
                      <RelatedProductItem
                        key={`related_item_${index}`}
                        slug={`/products/${node.fields.productSlug}`}
                        pageTitle={node.fields.productTitleShort}
                        brand={node.brand}
                        imageSrc={`/products/details/${node.productImage}.jpg`}
                        imageAlt={node.fields.productTitleShort}
                        bgColor={application}
                        displayWater={application.includes("water")}
                        displayHighTemperature={application.includes(
                          "high-temperature"
                        )}
                        displayGas={application.includes("gas")}
                        displayRefrigerant={application.includes("refrigerant")}
                      />
                    );
                  })}
                </ul>
              </div>
              <a
                className="uk-visible@l uk-position-center-left-out uk-position-small uk-hidden-hover"
                href="#"
                uk-slidenav-previous="true"
                uk-slider-item="previous"
              >
                <span className="c-visually__hidden">Previous</span>
              </a>
              <a
                className="uk-visible@l uk-position-center-right-out uk-position-small uk-hidden-hover"
                href="#"
                uk-slidenav-next="true"
                uk-slider-item="next"
              >
                <span className="c-visually__hidden">Next</span>
              </a>
            </div>
            <div className="uk-margin-large">
              <ul className="uk-slider-nav uk-dotnav uk-flex-center"></ul>
            </div>
          </div>
          <hr />
        </div>
      ): ""}
    </>
  );
};

const RelatedProductItem: React.FC<{
  slug: string;
  pageTitle: string;
  brand: string;
  imageSrc: string;
  imageAlt: string;
  bgColor: string;
  displayWater?: boolean;
  displayHighTemperature?: boolean;
  displayGas?: boolean;
  displayRefrigerant?: boolean;
}> = (props) => {
  const {
    slug,
    pageTitle,
    brand,
    imageSrc,
    imageAlt,
    bgColor,
    displayWater,
    displayHighTemperature,
    displayGas,
    displayRefrigerant,
  } = props;

  return (
    <li>
      <Link className="c-product-search__card  uk-card uk-card-small uk-link-toggle" to={slug}>
        <div className={`uk-card-header c-background-${bgColor} uk-light`}>
          <h3 className="uk-card-title uk-text-uppercase">{pageTitle}</h3>
        </div>
        <div className="uk-card-body uk-text-emphasis uk-margin-remove uk-padding-remove uk-animation-toggle">
          <div className="c-text-xsmall uk-padding-small">{brand}</div>
          <div className="uk-padding uk-animation-kenburns uk-animation-fast">
            <div className="uk-cover-container uk-background-muted">
              <canvas width="280" height="210"></canvas>
              {imageSrc && (
                <img
                  className="uk-margin-auto uk-display-block"
                  src={imageSrc}
                  alt={imageAlt}
                  uk-cover=""
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src="/load-error.jpg";
                    currentTarget.alt="Image couldn't load";
                  }}
                />
              )}
            </div>
          </div>
          {(displayWater ||
            displayHighTemperature ||
            displayGas ||
            displayRefrigerant) && (
            <div className="uk-padding-small uk-flex">
              {displayWater && <Icons iconType="water" iconSize="xsmall" />}
              {displayGas && (
                <Icons iconType="gas" iconSize="xsmall" />
              )}
              {displayRefrigerant && <Icons iconType="cold" iconSize="xsmall" />}
              {displayHighTemperature && (
                <Icons iconType="temp" iconSize="xsmall" />
              )}
            </div>
          )}
        </div>
      </Link>
    </li>
  );
};

export default RelatedProducts;
