import React from "react";
import { graphql } from "gatsby";

// import camelCase from "lodash/camelCase";
import toLower from "lodash/toLower";
import startCase from "lodash/startCase";

// components
import Seo from "../components/Layouts/Seo";
import Layout from "../components/Layouts/Layouts";
import LeadIn from "../components/Content/LeadIn/Index";
import RelatedProducts from "../components/Content/Product/Related";
import FeatureCaseStudy from "../components/Content/FeatureArticle/Dynamic";
import Icons from "../components/Content/Icons/Index";

// helpers
import { BrandAttributes } from "../helpers/hooks/BrandQuery";
import { UseBlogData } from "../helpers/hooks/BlogQuery";
import TextIcon from "../components/Content/TextIcon/Index";

interface Props {
  readonly data: PageQueryData;
}

const ProductTemplate: React.FC<Props> = ({ data }) => {
  const { productsJson, relatedProducts } = data;
  const product = productsJson;

  const productFilterType: any = toLower(product.filterType);
  const productBrand = product.fields.brandSlug;

  const featuredBlog = UseBlogData();

  const showAttributesFromFields = (data: any, attrToRemove: Array<string>) => {
    const filtered = data.filter(
      (entry: any) => attrToRemove.indexOf(entry) > -1
    );
    return filtered.map((entry: any) => {
      if (!entry.attributes) return entry;
      return {
        ...entry,
        attributes: showAttributesFromFields(entry.attributes, attrToRemove),
      };
    });
  };

  // Pull out product dimension values only
  const productDimensionsToShow = [
    "D",
    "D1",
    "D2",
    "H",
    "L",
    "L1",
    "L2",
    "L3",
    "S",
    "S1",
    "S2",
    "Z",
    "Z1",
    "Z2",
    "Z3",
    "A1",
    "d",
  ];
  const productDimensions = showAttributesFromFields(
    Object.keys(product),
    productDimensionsToShow
  );

  // Pull out product attributes values only
  const productSpecsToShow = [
    "filterFitting",
    "fields",
    "filterType",
    "oRingSealMaterial",
    "fittingMaterial",
    "maxOperatingPressure",
    "operatingTemperatureRange",
    "productWarranty",
  ];
  const productSpecifications = showAttributesFromFields(
    Object.keys(product),
    productSpecsToShow
  );

  // Pull out downloads
  const productDownloadsToShow = [
    "downloadTechnicalGuide",
    "downloadInstallationGuide",
    "downloadWarranty",
    "downloadRangeSheet",
  ];
  const productDownloads = showAttributesFromFields(
    Object.keys(product),
    productDownloadsToShow
  );
  const productAttributes = (entries: any) => {
    const keys = Object.keys(entries);

    return (
      <>
        {keys.map((key: string, index: number) => {
          const label = entries[key];
          if (!product[label as keyof typeof product]) {
            return;
          }
          return (
            <li key={`product_info_${index}`}>
              <strong className="c-text-xsmall uk-display-block">
                {labelClean(entries[key])}
              </strong>
              <span>
                {valueClean(
                  entries[key],
                  product[label as keyof typeof product]
                )}
              </span>
            </li>
          );
        })}
      </>
    );
  };

  const productDownloadIcon = (filePath: string | AttributeField) => {
    let icon;
    const file: any = filePath;

    if (file && file.includes("rfa")) {
      icon = "/icons/icon-cube-solid.svg";
    } else if (file && file.includes("ai")) {
      icon = "/icons/icon-drawing.svg";
    } else {
      icon = "/icons/icon-document.svg";
    }
    return icon;
  };

  const productDownloadData = (entries: any) => {
    const keys = Object.keys(entries);

    return keys.map((key: string) => {
      const label = productDownloads[key];
      const filePath = product[label as keyof typeof product];
      const iconType = productDownloadIcon(filePath);
      const obj = {};

      // downloadTechnicalGuide -> Technical Guide
      const subHeading = startCase(label).replace("Download", "");

      const dl = {
        removeDisplay: !filePath,
        subHeading,
        crossed: false,
        iconUrl: iconType,
        iconTitle: "",
        buttonUrl: `/downloads/${filePath}`,
        buttonTarget: "_blank",
        buttonRel: "noreferrer",
      };

      Object.assign(obj, dl);
      return obj;
    });
  };

  // Update the display of the label to be more friendly
  const labelClean = (label: string) => {
    switch (label) {
      case "fields":
        return "Size";
      case "filterType":
        return "Type";
      case "oRingSealMaterial":
        return "O-Ring/Seal Material";
      case "D":
      case "D1":
      case "D2":
      case "L":
      case "L1":
      case "L2":
      case "L3":
      case "S":
      case "S1":
      case "S2":
      case "Z":
      case "Z1":
      case "Z2":
      case "Z3":
      case "d":
      case "A1":
        return label;
      default:
        return startCase(label);
    }
  };

  // Update the value
  // append anything as needed
  const valueClean = (label: any, value: any) => {
    switch (label) {
      case "fields":
        return `${value.filterSize}`;
      case "operatingTemperatureRange":
        return `${value}`;
      case "D":
      case "D1":
      case "D2":
      case "L":
      case "L1":
      case "L2":
      case "L3":
      case "S":
      case "S1":
      case "S2":
      case "Z":
      case "Z1":
      case "Z2":
      case "Z3":
      case "d":
        return `${value}″`;
      case "A1":
        return `${value}°`;
      default:
        return value;
    }
  };

  return (
    <Layout>
      <Seo
        title={product.fields.productTitleLong}
        description={product.fields.brandSummary}
      />
      <section id="pagetop" className="uk-section">
        <div className="uk-container">
          <ul className="uk-breadcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/products">Products</a>
            </li>
            <li>
              <span>{product.fields?.productTitleLong}</span>
            </li>
          </ul>
          <div className="uk-grid-match uk-margin-medium" uk-grid="">
            <div className="uk-width-1-3@m">
              <div uk-scrollspy="target: > *; cls: uk-animation-slide-bottom-small; delay: 100">
                <h1>
                  {BrandAttributes && (
                    <div
                      className="uk-margin-top"
                      style={{
                        width: BrandAttributes[productBrand]?.width,
                      }}
                    >
                      <img
                        alt={BrandAttributes[productBrand]?.title}
                        className="uk-width-1-1"
                        src={BrandAttributes[productBrand]?.icon}
                      />
                    </div>
                  )}
                  <div className="uk-h5 uk-h4@m uk-h3@l uk-h2@xl uk-margin-remove-top">
                    {product.fields.productTitleShort}
                  </div>
                </h1>
                <p>{product.fields.brandSummary}</p>
                {productFilterType && (
                  <div className="uk-flex" uk-margin="">
                    <Icons
                      key={productFilterType}
                      iconType={productFilterType}
                      iconSize="small"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="uk-width-2-3@m uk-flex-top uk-flex-first uk-flex-first@s">
              <div className="uk-cover-container uk-background-muted">
                <canvas width="785" height="588"></canvas>
                {product.productImage && (
                  <img
                    src={`/products/details/${product.productImage}.jpg`}
                    alt={product.fields?.productTitleLong}
                    className="uk-margin-auto uk-display-block"
                    width=""
                    height=""
                    uk-cover=""
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "/load-error.jpg";
                      currentTarget.alt = "Image couldn't load";
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="c-products-spec uk-margin-large">
            <h2 className="uk-h5 uk-h4@m uk-h3@l uk-h2@xl">
              Product Specifications
            </h2>
            <hr className="uk-divider-small" />
            <div
              className="uk-flex-bottom uk-child-width-1-2@m uk-grid-match uk-grid-large uk-margin"
              uk-grid=""
            >
              <div>
                <ul className="uk-list uk-list-large uk-list-divider">
                  <li>
                    <strong className="c-text-xsmall uk-display-block">
                      Product Code
                    </strong>
                    <span>{product.productCode || "-"}</span>
                  </li>
                  {productAttributes(productSpecifications)}
                </ul>
              </div>
              <div>
                {product.techDrawing && (
                  <img
                    src={`/products/line/${product.techDrawing}.jpg`}
                    alt={`Line Drawing - ${product.fields?.productTitleShort}`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "/load-error.jpg";
                      currentTarget.alt = "Image couldn't load";
                    }}
                  />
                )}
                <ul className="uk-list uk-list-large uk-list-divider uk-margin-remove">
                  {productAttributes(productDimensions)}
                </ul>
              </div>
            </div>
            <div className="uk-margin-large">
              <a
                href={`/products/${product.fields.brandSlug.replace(
                  "maxi-pro",
                  "maxipro"
                )}`}
                className="uk-button uk-button-primary"
              >
                Discover Features
              </a>
            </div>
          </div>
          <hr />
          {productDownloadData.length ? (
            <>
              <div className="uk-margin-large">
                <h2 className="uk-h5 uk-h4@m uk-h3@l uk-h2@xl">Downloads</h2>
                <hr className="uk-divider-small" />
                <div className="uk-text-bold">
                  <TextIcon
                    removePadding={true}
                    data={productDownloadData(productDownloads)}
                  />
                </div>
              </div>
              <hr />
            </>
          ) : (
            ""
          )}
          <RelatedProducts data={relatedProducts} />
          <FeatureCaseStudy
            data={featuredBlog[productBrand.replace(/-/g, "")]}
          />
        </div>
      </section>

      <LeadIn
        section="primary"
        brandMediaUrl="/icons/icon-search-white.svg"
        brandMediaTitle="Download"
        brandMediaWidth="69px"
        summary="Find out more about the labor saving >B< Press products."
        paddingSize="small"
        buttonValue="Find out more"
        buttonUrl="/products/b-press"
      />
    </Layout>
  );
};

interface Attributes {
  brand: string;
  downloadTechnicalGuide: string;
  downloadInstallationGuide: string;
  downloadRangeSheet: string;
  downloadWarranty: string;
  filterFitting: string;
  filterType: string;
  fittingMaterial: string;
  maxOperatingPressure: string;
  oRingSealMaterial: string;
  operatingTemperatureRange: string;
  productCode: string;
  productImage: string;
  productWarranty: string;
  techDrawing: string;
  D: string;
  D1: string;
  D2: string;
  H: string;
  L: string;
  L1: string;
  L2: string;
  L3: string;
  S: string;
  S1: string;
  S2: string;
  Z: string;
  Z1: string;
  Z2: string;
  Z3: string;
  A1: string;
  d: string;
  fields: AttributeField;
}

interface AttributeField {
  productSlug: string;
  productTitleLong: string;
  productTitleShort: string;
  brandSummary: string;
  brandSlug: string;
}

interface PageQueryData {
  productsJson: Attributes;
  relatedProducts: {
    edges: {
      fields: AttributeField;
    };
  };
}

export const pageQuery = graphql`
  query ProductBySlug(
    $limit: Int!
    $slug: String!
    $brand: String!
    $application: String!
    $fittingType: String!
  ) {
    productsJson(fields: { productSlug: { eq: $slug } }) {
      brand
      downloadTechnicalGuide
      downloadInstallationGuide
      downloadRangeSheet
      downloadWarranty
      filterFitting
      filterType
      fittingMaterial
      maxOperatingPressure
      oRingSealMaterial
      operatingTemperatureRange
      productCode
      productImage
      productWarranty
      techDrawing
      D
      D1
      D2
      H
      L
      L1
      L2
      L3
      S
      S1
      S2
      Z
      Z1
      Z2
      Z3
      A1
      d
      fields {
        productSlug
        productTitleLong
        productTitleShort
        brandSummary
        brandSlug
        filterSize
      }
    }
    relatedProducts: allProductsJson(
      limit: $limit
      filter: {
        fields: { brandSlug: { eq: $brand } }
        filterType: { eq: $application }
        filterFitting: { eq: $fittingType }
      }
      # sort: { fields: filterSize, order: ASC }
    ) {
      edges {
        node {
          filterType
          productImage
          brand
          fields {
            productSlug
            productTitleLong
            productTitleShort
            brandSummary
            brandSlug
            filterSize
          }
        }
      }
    }
  }
`;

export default ProductTemplate;
